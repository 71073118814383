<template>

  <div v-if="session > 0">
    <kategori-list-add-new
      :is-add-new-kategori-sidebar-active.sync="isAddNewKategoriSidebarActive"
      :role-options="roleOptions"
      :plan-options="planOptions"
      @refetch-data="refetchData"
    />

    <!-- Filters -->
    <!--kategori-list-filters
      :role-filter.sync="roleFilter"
      :plan-filter.sync="planFilter"
      :status-filter.sync="statusFilter"
      :nama-filter.sync="namaFilter"
      :role-options="roleOptions"
      :plan-options="planOptions"
      :status-options="statusOptions"
      :nama-options="namaOptions"
    /-->

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />

              <b-button
                variant="primary"
                :to="{ name: 'apps-kategori-produk-add'}"
              >
                <span class="text-nowrap">Tambah Kategori Produk</span>
              </b-button>

              
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refKategoriListTable"
        class="position-relative text-center"
        :items="fetchKategori"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        busy.sync="true"
        show-empty
      >
        <template #table-busy>
          <b-button
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner small /> Loading...
          </b-button>
        </template>

        <!-- Column: Group Item -->
        <template #cell(kategori)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.avatar"
                :text="avatarText(data.item.fullName)"
                :variant="`light-${resolveKategoriRoleVariant(data.item.role)}`"
                :to="{ name: 'apps-group-item-view', params: { id: data.item.id } }"
              />
            </template>
            <b-link
              :to="{ name: 'apps-group-item-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.fullName }}
            </b-link>
            <small class="text-muted">@{{ data.item.sitename }}</small>
          </b-media>
        </template>

        <!-- Column: Role -->
        <template #cell(role)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveKategoriRoleIcon(data.item.role)"
              size="18"
              class="mr-50"
              :class="`text-${resolveKategoriRoleVariant(data.item.role)}`"
            />
            <span class="align-text-top text-capitalize">{{ data.item.role }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveKategoriStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item :to="{ name: 'apps-kategori-produk-edit', params: { cat_kode:data.item.cat_kode , cat_bu: data.item.cat_bu, cat_prodline:data.item.cat_prodline } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item @click="showMsgBoxTwo(data.item.cat_kode,data.item.cat_bu,data.item.cat_prodline)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>

          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalKategori"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>

      <template #code>
        {{ codeMessageBox }}
      </template>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BFormGroup,
  BBadge, BDropdown, BDropdownItem, BPagination, VBModal, BSpinner
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
// buat ambil api
import axios from '@axios'
// buat nampil data
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import KategoriListFilters from './KategoriListFilters.vue'
import useKategoriList from './useKategoriList'
import kategoriStoreModule from '../kategoriStoreModule'
import KategoriListAddNew from './KategoriListAddNew.vue'
import { codeMessageBox, codePrevent } from './code'
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
import router from '@/router'
import useAppConfig from '@core/app-config/useAppConfig'

export default {
  components: {
    KategoriListFilters,
    KategoriListAddNew,
    BFormGroup,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    vSelect,
    ToastificationContent,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      boxOne: '',
      boxTwo: '',
      codeMessageBox,
      codePrevent,
      name: '',
      nameState: null,
      submittedNames: [],
      busy:false,
    }
  },
  setup() {
    const session = ref([])
    onMounted(async () => {
      let char = (router.currentRoute.name).split('-')
      let finChar = ''
      for (let i = 0; i < (char.length)-1; i++) {
        const element = char[i];
        finChar += element+'-'
      }
      console.log(finChar.slice(0, -1));
      let result = await axios.post(`${useAppConfig().AppUrl}global/cekAksesPrevilege`, {emp_id: localStorage.getItem('emp_id'), route_name: finChar.slice(0, -1)})
      session.value= result.data[0].data[0].previlege
    })

    const KATEGORI_APP_STORE_MODULE_NAME = 'app-kategori'

    // Register module
    if (!store.hasModule(KATEGORI_APP_STORE_MODULE_NAME)) store.registerModule(KATEGORI_APP_STORE_MODULE_NAME, kategoriStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(KATEGORI_APP_STORE_MODULE_NAME)) store.unregisterModule(KATEGORI_APP_STORE_MODULE_NAME)
    })

    const isAddNewKategoriSidebarActive = ref(false)

    const roleOptions = [

    ]

    const planOptions = [
    ]

    const {
      fetchKategori,
      tableColumns,
      perPage,
      currentPage,
      totalKategori,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refKategoriListTable,
      refetchData,

      // UI
      resolveKategoriRoleVariant,
      resolveKategoriRoleIcon,
      resolveKategoriStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
    } = useKategoriList()

    return {
      session,
      // Sidebar
      isAddNewKategoriSidebarActive,

      fetchKategori,
      tableColumns,
      perPage,
      currentPage,
      totalKategori,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refKategoriListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveKategoriRoleVariant,
      resolveKategoriRoleIcon,
      resolveKategoriStatusVariant,

      roleOptions,
      planOptions,

      // Extra Filters
      roleFilter,
      planFilter,
    }
  },
  methods: {
    //  rowClass(item, key) {
    //     if (item.cs_category == 'PP') return 'table-success' 
    //   },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.nameState = valid
      return valid
    },
    resetModal() {
      this.name = ''
      this.nameState = null
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },

    async showMsgBoxTwo(id, id_bu, id_prodline) {
      this.boxTwo = id
      console.log(id)
      this.$bvModal
        .msgBoxConfirm('Apakah yakin ingin menghapus data ini?', {
          title: 'Konfirmasi',
          size: 'sm',

          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          this.boxTwo = value
          if (value === true) {
            const params = new URLSearchParams()
            params.append('param', 'delete_keterangan')
            params.append('cat_kode', id)
            params.append('cat_bu', id_bu)
            params.append('cat_prodline', id_prodline)
            const result = await axios.post(`${useAppConfig().AppUrl}mst_kategori_product/delete`, params)
            if (result.data.message === 'Berhasil menghapus data') {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Notification',
                  icon: 'BellIcon',
                  text: result.data.message,
                  variant: 'success',
                },
              })
              this.refetchData()
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Warning',
                  icon: 'BellIcon',
                  text: 'Gagal menghapus data!',
                  variant: 'danger',
                },
              })
            }
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
